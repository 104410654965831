import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllProjects from "../components/projects/allProjects";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/projects.css";

const Projects = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Projects | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="projects" />
				<div className="content-wrapper">
					{/* <div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div> */}
					<div className="projects-container">
						<div className="title projects-title">
							Things I’ve made.
						</div>

						<div className="subtitle projects-subtitle">
							Over the years, I've been involved in various projects, and I take pride in the advancements achieved. Several of these projects are open-source, welcoming exploration and contributions from others. Should you find interest in any of my endeavors, I invite you to delve into the code and propose any enhancements or suggestions you may have. Collaboration offers a wonderful opportunity for learning and personal development, and I enthusiastically welcome fresh ideas and feedback from others.						</div>

						<div className="projects-list">
							<AllProjects />
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Projects;
