const SEO = [
	{
		page: "home",
		description:
			"I possess over a decade of experience as a backend developer specializing in Node.js and Python. My expertise lies in crafting scalable, secure, and dependable web applications, employing a range of frameworks and technologies. I thrive on tackling intricate challenges and expanding my skill set. My dedication to producing top-notch code adhering to best practices and industry norms is unwavering. Continuously seeking fresh challenges and avenues for professional growth drives my passion for development.",
		keywords: ["Juan Nieblas", "Juan Pablo Nieblas Caraveo", "Juan P. Nieblas"],
	},

	{
		page: "about",
		description:
			"I’m Juan Nieblas. I live in Sonora, MX. And I love the technology.",
		keywords: ["Juan Nieblas", "Juan Pablo Nieblas Caraveo", "Juan P. Nieblas"],
	},

	{
		page: "projects",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made.",
		keywords: ["Juan Nieblas", "Juan Pablo Nieblas Caraveo", "Juan P. Nieblas"],
	},

	{
		page: "contact",
		description:
			"I appreciate your interest in reaching out to me.",
		keywords: ["Juan Nieblas", "Juan Pablo Nieblas Caraveo", "Juan P. Nieblas"],
	},
];

export default SEO;

