import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/contact.css";

const Contact = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "contact");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Contact | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="contact" />
				<div className="content-wrapper">
					{/* <div className="contact-logo-container">
						<div className="contact-logo">
							<Logo width={46} />
						</div>
					</div> */}

					<div className="contact-container">
						<div className="title contact-title">
							Let's Get in Touch
						</div>

						<div className="subtitle contact-subtitle">
						I appreciate your interest in reaching out to me. Your feedback, inquiries, and ideas are all welcome. If you have any specific questions or comments, please don't hesitate to reach out directly via email at&nbsp;{" "}
							<a href={`mailto:${INFO.main.email}`}>
								{INFO.main.email}
							</a>
							. I strive to reply to all messages within 24 hours, although there may be delays during particularly busy periods. Once again, thank you for your interest, and I eagerly anticipate hearing from you!							
						</div>
					</div>

					<div className="socials-container">
						<div className="contact-socials">
							<Socials />
						</div>
					</div>

					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Contact;
