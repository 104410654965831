const INFO = {
	main: {
		title: "Juan P. Nieblas",
		name: "Juan P. Nieblas",
		email: "juan@nieblas.dev",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/juanykty",
		linkedin: "https://www.linkedin.com/in/juanykty/",
	},

	homepage: {
		title: "Full-stack web developer and technology consultant.",
		description:
			"I possess over a decade of experience as a backend developer specializing in Node.js and Python. My expertise lies in crafting scalable, secure, and dependable web applications, employing a range of frameworks and technologies. I thrive on tackling intricate challenges and expanding my skill set. My dedication to producing top-notch code adhering to best practices and industry norms is unwavering. Continuously seeking fresh challenges and avenues for professional growth drives my passion for development.",
	},

	about: {
		title: "I’m Juan Nieblas. I live in Sonora, MX. And I love the technology.",
		description:
			"I've had the chance to work on various projects during my career, and I'm pleased with the advancements I've made. My experience includes working independently at Inmobiliaria Yuuma, Mundo Minero, Allegro Response Teleservices, Eleven Technologies, and CatOverflow, as well as with companies like Officemax and Rivka Development. I believe collaborating with others is crucial for personal and professional growth, and I'm always open to fresh perspectives and feedback.",
	},

	articles: {
		title: "Here I express my passion for pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"My long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Inmobiliaria Yuuma",
			description:
				"Real Estate CMS.",
			logo: "logo_yuuma.png",
			linkText: "View Project",
			link: "https://inmobiliariayuuma.com",
		},

		{
			title: "Mundo Minero",
			description:
				"Online Magazine.",
			logo: "mm.jpg",
			linkText: "View Project",
			link: "https://mundominero.mx",
		},

		{
			title: "Allegro Response Teleservices",
			description:
				"Brand Website.",
			logo: "allegro.png",
			linkText: "View Project",
			link: "https://allegroresponse.com",
		},

		{
			title: "Eleven Tecnologies",
			description:
				"software consulting company.",
			logo: "eleven.jpg",
			linkText: "View Project",
			link: "https://eleven-technologies.com",
		},

		{
			title: "CatOverflow",
			description:
				"Blog.",
			logo: "catoverflow.png",
			linkText: "View Project",
			link: "https://catoverflow.com.mx",
		},
	],
};

export default INFO;
