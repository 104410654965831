import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						{/* <div className="work">
							<img
								src="./facebook.png"
								alt="facebook"
								className="work-image"
							/>
							<div className="work-title">Karuna Communications</div>
							<div className="work-subtitle">
								IT Director
							</div>
							<div className="work-duration">2024 - Present</div>
						</div> */}
						<div className="work">
							<img
								src="./work1.png"
								alt="Rivka Development"
								className="work-image"
							/>
							<div className="work-title">Rivka Development</div>
							<div className="work-subtitle">
								Head of Technology
							</div>
							<div className="work-duration">2019 - Present</div>
						</div>

						<div className="work">
							<img
								src="./allegro.png"
								alt="Allegro Response Teleservices"
								className="work-image"
							/>
							<div className="work-title">Allegro Response Teleservices</div>
							<div className="work-subtitle">
								Software Developer | IT Support
							</div>
							<div className="work-duration">2015 - 2019</div>
						</div>
						<div className="work">
							<img
								src="./officemax.jpg"
								alt="Officemax"
								className="work-image"
							/>
							<div className="work-title">Officemax</div>
							<div className="work-subtitle">
								IT Specialist
							</div>
							<div className="work-duration">2012 - 2014</div>
						</div>

					</div>
				}
			/>
		</div>
	);
};

export default Works;
